<template>
  <div class="right">
    <div class="top">
      <p>{{ name }}</p>
      <div class="img">
        <img
          v-if="vcq.variant && vcq.variant.preview"
          :src="vcq.variant.preview"
          :style="`background: #${vcq.color}`"
        />
        <img v-else :src="settings.previewEmpty" />
      </div>
    </div>
    <div class="bottom">
      <p>
        Всего типов:
        <span>{{ totalTypes }}</span>
      </p>
      <p>
        Всего автомобилей :
        <span>{{ totalCars }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields, createHelpers } from "vuex-map-fields";

const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

export default {
  computed: {
    ...mapCarFields(["vcq"]),
    ...mapFields(["settings"]),
    ...mapGetters(["totalTypes", "totalCars"]),
    name() {
      return this.vcq.variant?.name;
    },
  },
};
</script>
