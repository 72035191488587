<template>
  <li>
    <p>{{ title }}</p>
    <input
      v-if="!items || items[0].model_id <= 0"
      class="selectio"
      :value="placeholder"
      disabled
    />
    <select v-else-if="type === 'select'" v-model="itemId" class="selectio">
      <option disabled value="undefined">Выберите модель</option>
      <option v-for="i in items" :key="i.id" :value="i.id">
        {{ i.name }}
      </option>
    </select>
    <div v-else-if="type === 'images'" class="images">
      <img
        v-for="i in items"
        :key="i.id"
        class="pointer"
        :src="i.id === itemId ? i.preview[1] : i.preview[0]"
        :alt="i.name"
        :title="i.name"
        @click="itemId = i.id"
      />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: null,
    },
    value: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Выберите предыдущее свойство",
    },
    type: {
      type: String,
      default: "select",
    },
  },
  computed: {
    itemId: {
      get() {
        return this.value?.id;
      },
      set(val) {
        const item = this.items.find((i) => i.id === val);
        this.$emit("input", item);
      },
    },
  },
};
</script>

<style scoped>
.images {
  width: 475px;
  display: flex;
  justify-content: space-between;
}
</style>
