<template>
  <li>
    <p>Марка</p>
    <select v-model="mId" class="selectio">
      <option disabled value="undefined">Выберите марку</option>
      <template v-for="m in marks">
        <option v-if="m.id > 0" :key="m.id" :value="m.id">
          {{ m.name }}
        </option>
      </template>
    </select>
    <!--    <button :disabled="mId === 0" @click="mId = 0">Нет моего автомобиля</button>-->
  </li>
</template>

<script>
export default {
  props: {
    marks: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    mId: {
      get() {
        return this.value?.id;
      },
      set(val) {
        const mark = this.marks.find((m) => m.id === val);
        this.$emit("input", mark);
      },
    },
  },
};
</script>
