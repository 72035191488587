<template>
  <div class="text_square text_square_main">
    <p>Цвет</p>
    <div class="square">
      <div class="square_color" :style="`background: #${value};`"></div>
      <div class="text_square text_squareS">
        <div class="square">
          <div
            v-for="c in palette"
            :key="c"
            class="square_color"
            :style="`background: #${c}`"
            @click="setColor(c)"
          ></div>
          <div
            v-for="i in 7"
            :key="i"
            class="square_color square_color__empty"
            :style="
              customPalette[i - 1] ? `background: #${customPalette[i - 1]}` : ''
            "
            @click="setColor(customPalette[i - 1])"
          ></div>
          <div
            class="square_color square_color__add color-picker"
            @click="shownPallete = !shownPallete"
          ></div>
          <chrome-picker
            v-if="shownPallete"
            v-model="customColor"
            class="color-picker_pallete"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

import { Chrome } from "vue-color";

export default {
  components: { "chrome-picker": Chrome },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colors: [],
      shownPallete: false,
      timerId: null,
    };
  },
  computed: {
    ...mapGetters(["customPalette"]),
    ...mapFields(["settings"]),
    palette() {
      return this.settings.palette;
    },
    customColor: {
      get() {
        return this.value;
      },
      set(c) {
        if (c.source !== "hsva") return;
        const color = c.hex8.substring(1);
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.$store.commit("pushColor", color);
        }, 500);
        this.setColor(color);
      },
    },
  },
  methods: {
    setColor(c) {
      this.$emit("input", c);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  position: relative;
}
.color-picker_pallete {
  position: absolute;
  bottom: -250px;
}
.square_color__empty {
  background: #dedede;
  background-image: repeating-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.15) 6px,
    transparent 0,
    transparent 15px
  );
}
</style>
