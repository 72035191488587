<template>
  <div>
    <OptionsCar class="top" />
    <AddRemoveCar class="bottom" />
  </div>
</template>

<script>
import OptionsCar from "./options-car/";
import AddRemoveCar from "./add-remove-car";

export default {
  components: {
    OptionsCar,
    AddRemoveCar,
  },
};
</script>
