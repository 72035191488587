<template>
  <div>
    <h2>Вы можете выбрать несколько автомобилей для брендирования</h2>

    <a class="switch_BIG prev" @click="previousCar">
      <span>
        <img src="@/assets/imgs/Big-prevActive.png" />
      </span>
    </a>
    <a class="switch_BIG next" @click="nextCar">
      <span>
        <img src="@/assets/imgs/Big-nextActive.png" />
      </span>
    </a>

    <div class="left_right">
      <SelectCar class="left" />
      <Car class="right" />
    </div>
  </div>
</template>

<script>
import SelectCar from "./select-car/";
import Car from "./car";

export default {
  components: { SelectCar, Car },
  methods: {
    previousCar() {
      this.$store.commit("prevCar");
    },
    nextCar() {
      this.$store.commit("nextCar");
    },
  },
};
</script>
