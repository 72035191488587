<template>
  <li class="icon">
    <template v-for="m in marks">
      <a v-if="m.id !== 0" :key="m.id" :class="{ active: m.id === mId }">
        <img :src="m.logo" :alt="m.name" @click="mId = m.id" />
      </a>
    </template>
  </li>
</template>

<script>
export default {
  props: {
    marks: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    mId: {
      get() {
        return this.value?.id;
      },
      set(val) {
        const mark = this.marks.find((m) => m.id === val);
        this.$emit("input", mark);
      },
    },
  },
};
</script>
