<template>
  <div>
    <div class="length">
      <span>Длина</span>
      <input
        :value="localSize.length"
        type="number"
        class="selectio"
        placeholder="Длина"
        @input="setSize('length', $event.target.value)"
      />
    </div>
    <i class="fal fa-times"></i>
    <div class="hight">
      <span>Высота</span>
      <input
        :value="localSize.height"
        type="number"
        class="selectio"
        placeholder="Высота"
        @input="setSize('height', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variants: {
      type: Array,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    size: {
      type: Object,
      default: null,
    },
  },
  computed: {
    localSize() {
      return this.size || {};
    },
  },
  watch: {
    localSize: "emitVariant",
    variants: "emitVariant",
  },
  methods: {
    closestVariant() {
      const goal = +this.localSize?.length;
      if (!goal) return (this.variants?.length || null) && this.variants[0];
      return this.variants?.reduce((prev, curr) =>
        Math.abs(curr.length - goal) < Math.abs(prev.length - goal)
          ? curr
          : prev
      );
    },
    emitVariant() {
      const variant = this.closestVariant();
      this.$emit("input", variant);
    },
    setSize(key, val) {
      this.$emit("update:size", { ...this.size, [key]: val });
    },
  },
};
</script>
