<template>
  <div class="quantity">
    <p>Кол-во</p>
    <div class="wrap-selectio">
      <input
        id="quantity"
        v-model="quantity"
        type="number"
        class="input-number selectio"
        min="1"
      />
      <div class="arrow">
        <a id="numUp" @click="increaseCarQuantity"
          ><i class="far fa-angle-up"></i
        ></a>
        <a id="numDown" @click="decreaseCarQuantity"
          ><i class="far fa-angle-down"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    quantity: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", +v);
      },
    },
  },
  methods: {
    increaseCarQuantity() {
      this.quantity++;
    },
    decreaseCarQuantity() {
      if (this.quantity <= 1) return;
      this.quantity--;
    },
  },
};
</script>
